body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;

@layer base {
  html {
    @apply text-neutral-800;
  }
  html.dark {
    @apply text-neutral-50;
    @apply bg-neutral-800;
  }
}

@layer utilities {
  .h-screen-margin {
    @apply max-h-screen;
    max-height: calc(100vh - .75rem - .75rem);
  }
}

@tailwind components;
@tailwind utilities;
